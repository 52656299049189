*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

.\!tw-fixed {
  position: fixed !important
}

.tw-fixed {
  position: fixed
}

.\!tw-absolute {
  position: absolute !important
}

.tw-absolute {
  position: absolute
}

.tw-relative {
  position: relative
}

.tw-sticky {
  position: -webkit-sticky;
  position: sticky
}

.tw-inset-0 {
  inset: 0px
}

.tw-inset-x-0 {
  left: 0px;
  right: 0px
}

.\!tw-bottom-16 {
  bottom: 4rem !important
}

.tw--top-2\/4 {
  top: -50%
}

.tw-bottom-0 {
  bottom: 0px
}

.tw-bottom-5 {
  bottom: 1.25rem
}

.tw-left-0 {
  left: 0px
}

.tw-left-1\/2 {
  left: 50%
}

.tw-left-2\/4 {
  left: 50%
}

.tw-right-3 {
  right: 0.75rem
}

.tw-right-4 {
  right: 1rem
}

.tw-top-0 {
  top: 0px
}

.tw-top-1\/2 {
  top: 50%
}

.tw-top-3 {
  top: 0.75rem
}

.tw-top-\[52px\] {
  top: 52px
}

.tw-z-0 {
  z-index: 0
}

.tw-z-10 {
  z-index: 10
}

.tw-z-20 {
  z-index: 20
}

.tw-z-50 {
  z-index: 50
}

.tw-z-\[-1\] {
  z-index: -1
}

.tw-m-0 {
  margin: 0px
}

.tw-m-auto {
  margin: auto
}

.\!tw-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important
}

.tw--mx-4 {
  margin-left: -1rem;
  margin-right: -1rem
}

.tw--mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem
}

.tw-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.tw-my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.\!tw-mb-1 {
  margin-bottom: 0.25rem !important
}

.\!tw-mb-2 {
  margin-bottom: 0.5rem !important
}

.\!tw-mb-2\.5 {
  margin-bottom: 0.625rem !important
}

.\!tw-mb-4 {
  margin-bottom: 1rem !important
}

.\!tw-mb-5 {
  margin-bottom: 1.25rem !important
}

.\!tw-mb-8 {
  margin-bottom: 2rem !important
}

.\!tw-ml-1 {
  margin-left: 0.25rem !important
}

.\!tw-ml-4 {
  margin-left: 1rem !important
}

.\!tw-mt-5 {
  margin-top: 1.25rem !important
}

.tw-mb-1 {
  margin-bottom: 0.25rem
}

.tw-mb-4 {
  margin-bottom: 1rem
}

.tw-mb-5 {
  margin-bottom: 1.25rem
}

.tw-mb-6 {
  margin-bottom: 1.5rem
}

.tw-ml-auto {
  margin-left: auto
}

.tw-mt-1 {
  margin-top: 0.25rem
}

.tw-mt-3 {
  margin-top: 0.75rem
}

.tw-mt-3\.5 {
  margin-top: 0.875rem
}

.tw-mt-5 {
  margin-top: 1.25rem
}

.tw-mt-auto {
  margin-top: auto
}

.\!tw-mt-4 {
  margin-top: 1rem !important
}

.\!tw-flex {
  display: flex !important
}

.tw-flex {
  display: flex
}

.tw-inline-flex {
  display: inline-flex
}

.tw-grid {
  display: grid
}

.tw-aspect-square {
  aspect-ratio: 1 / 1
}

.\!tw-h-10 {
  height: 2.5rem !important
}

.tw-h-10 {
  height: 2.5rem
}

.tw-h-12 {
  height: 3rem
}

.tw-h-24 {
  height: 6rem
}

.tw-h-4 {
  height: 1rem
}

.tw-h-\[80vh\] {
  height: 80vh
}

.tw-h-full {
  height: 100%
}

.tw-max-h-\[80vh\] {
  max-height: 80vh
}

.\!tw-min-h-0 {
  min-height: 0px !important
}

.tw-min-h-11 {
  min-height: 2.75rem
}

.tw-min-h-24 {
  min-height: 6rem
}

.tw-w-10 {
  width: 2.5rem
}

.tw-w-11\/12 {
  width: 91.666667%
}

.tw-w-24 {
  width: 6rem
}

.tw-w-72 {
  width: 18rem
}

.tw-w-96 {
  width: 24rem
}

.tw-w-\[90vw\] {
  width: 90vw
}

.tw-w-full {
  width: 100%
}

.\!tw-min-w-0 {
  min-width: 0px !important
}

.\!tw-min-w-10 {
  min-width: 2.5rem !important
}

.tw-min-w-12 {
  min-width: 3rem
}

.tw-min-w-24 {
  min-width: 6rem
}

.tw-min-w-3 {
  min-width: 0.75rem
}

.tw-max-w-4xl {
  max-width: 56rem
}

.tw-max-w-64 {
  max-width: 16rem
}

.tw-max-w-80 {
  max-width: 20rem
}

.tw-max-w-96 {
  max-width: 24rem
}

.tw-max-w-full {
  max-width: 100%
}

.tw-max-w-lg {
  max-width: 32rem
}

.tw-max-w-md {
  max-width: 28rem
}

.tw-flex-auto {
  flex: 1 1 auto
}

.tw-flex-initial {
  flex: 0 1 auto
}

.tw--translate-x-2\/4 {
  --tw-translate-x: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.tw--translate-y-2\/4 {
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.tw-translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.tw-translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.tw-transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.\!tw-cursor-default {
  cursor: default !important
}

.tw-cursor-default {
  cursor: default
}

.tw-cursor-pointer {
  cursor: pointer
}

.tw-grid-cols-\[repeat\(auto-fill\,_minmax\(270px\,_1fr\)\)\] {
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr))
}

.tw-flex-col {
  flex-direction: column
}

.tw-flex-col-reverse {
  flex-direction: column-reverse
}

.tw-flex-wrap {
  flex-wrap: wrap
}

.tw-items-start {
  align-items: flex-start
}

.tw-items-center {
  align-items: center
}

.tw-justify-end {
  justify-content: flex-end
}

.tw-justify-center {
  justify-content: center
}

.tw-justify-between {
  justify-content: space-between
}

.tw-gap-2 {
  gap: 0.5rem
}

.tw-gap-2\.5 {
  gap: 0.625rem
}

.tw-gap-4 {
  gap: 1rem
}

.tw-gap-x-1 {
  -webkit-column-gap: 0.25rem;
          column-gap: 0.25rem
}

.tw-gap-x-1\.5 {
  -webkit-column-gap: 0.375rem;
          column-gap: 0.375rem
}

.tw-gap-x-2 {
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem
}

.tw-gap-x-2\.5 {
  -webkit-column-gap: 0.625rem;
          column-gap: 0.625rem
}

.tw-gap-x-4 {
  -webkit-column-gap: 1rem;
          column-gap: 1rem
}

.tw-gap-x-8 {
  -webkit-column-gap: 2rem;
          column-gap: 2rem
}

.tw-gap-y-1 {
  row-gap: 0.25rem
}

.tw-gap-y-2 {
  row-gap: 0.5rem
}

.tw-overflow-hidden {
  overflow: hidden
}

.tw-overflow-y-auto {
  overflow-y: auto
}

.tw-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.tw-whitespace-nowrap {
  white-space: nowrap
}

.tw-whitespace-pre-wrap {
  white-space: pre-wrap
}

.tw-break-words {
  overflow-wrap: break-word
}

.\!tw-rounded-lg {
  border-radius: 0.5rem !important
}

.tw-rounded {
  border-radius: 0.25rem
}

.tw-rounded-3xl {
  border-radius: 1.5rem
}

.tw-rounded-\[30px\] {
  border-radius: 30px
}

.tw-rounded-full {
  border-radius: 9999px
}

.tw-rounded-lg {
  border-radius: 0.5rem
}

.tw-rounded-xl {
  border-radius: 0.75rem
}

.\!tw-rounded-b-none {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important
}

.\!tw-rounded-t-2xl {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important
}

.tw-rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem
}

.tw-rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem
}

.tw-rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem
}

.tw-rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem
}

.\!tw-border {
  border-width: 1px !important
}

.tw-border {
  border-width: 1px
}

.tw-border-b-0 {
  border-bottom-width: 0px
}

.tw-border-t-0 {
  border-top-width: 0px
}

.\!tw-border-solid {
  border-style: solid !important
}

.tw-border-solid {
  border-style: solid
}

.\!tw-border-neutral-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(212 212 212 / var(--tw-border-opacity)) !important
}

.\!tw-border-neutral-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(163 163 163 / var(--tw-border-opacity)) !important
}

.\!tw-border-neutral-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(82 82 82 / var(--tw-border-opacity)) !important
}

.tw-border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity))
}

.tw-border-neutral-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 163 163 / var(--tw-border-opacity))
}

.tw-border-neutral-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 82 / var(--tw-border-opacity))
}

.\!tw-bg-transparent {
  background-color: transparent !important
}

.tw-bg-blue-650 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 103 243 / var(--tw-bg-opacity))
}

.tw-bg-neutral-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity))
}

.tw-bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity))
}

.tw-bg-neutral-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity))
}

.tw-bg-neutral-925 {
  --tw-bg-opacity: 1;
  background-color: rgb(18 18 18 / var(--tw-bg-opacity))
}

.tw-bg-neutral-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(10 10 10 / var(--tw-bg-opacity))
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.\!tw-p-0 {
  padding: 0px !important
}

.\!tw-p-2 {
  padding: 0.5rem !important
}

.tw-p-0 {
  padding: 0px
}

.tw-p-1 {
  padding: 0.25rem
}

.tw-p-1\.5 {
  padding: 0.375rem
}

.tw-p-2 {
  padding: 0.5rem
}

.tw-p-4 {
  padding: 1rem
}

.tw-p-6 {
  padding: 1.5rem
}

.\!tw-px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important
}

.tw-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.tw-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.tw-px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.tw-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.tw-py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem
}

.tw-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.tw-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.tw-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.\!tw-pb-16 {
  padding-bottom: 4rem !important
}

.\!tw-pt-4 {
  padding-top: 1rem !important
}

.tw-pb-3 {
  padding-bottom: 0.75rem
}

.tw-pb-4 {
  padding-bottom: 1rem
}

.tw-pb-5 {
  padding-bottom: 1.25rem
}

.tw-pl-3 {
  padding-left: 0.75rem
}

.tw-pr-12 {
  padding-right: 3rem
}

.tw-pt-0 {
  padding-top: 0px
}

.tw-pt-1 {
  padding-top: 0.25rem
}

.tw-pt-2 {
  padding-top: 0.5rem
}

.tw-pt-2\.5 {
  padding-top: 0.625rem
}

.tw-pt-3 {
  padding-top: 0.75rem
}

.tw-pt-4 {
  padding-top: 1rem
}

.tw-pt-5 {
  padding-top: 1.25rem
}

.tw-pt-8 {
  padding-top: 2rem
}

.tw-text-center {
  text-align: center
}

.\!tw-text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important
}

.\!tw-text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important
}

.\!tw-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important
}

.\!tw-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important
}

.\!tw-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important
}

.\!tw-text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important
}

.tw-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem
}

.tw-text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.tw-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.\!tw-font-bold {
  font-weight: 700 !important
}

.\!tw-font-extrabold {
  font-weight: 800 !important
}

.\!tw-font-medium {
  font-weight: 500 !important
}

.\!tw-font-semibold {
  font-weight: 600 !important
}

.tw-font-bold {
  font-weight: 700
}

.tw-font-extrabold {
  font-weight: 800
}

.tw-font-semibold {
  font-weight: 600
}

.tw-uppercase {
  text-transform: uppercase
}

.\!tw-text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important
}

.\!tw-text-inherit {
  color: inherit !important
}

.\!tw-text-neutral-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(212 212 212 / var(--tw-text-opacity)) !important
}

.\!tw-text-neutral-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(10 10 10 / var(--tw-text-opacity)) !important
}

.\!tw-text-red-default {
  --tw-text-opacity: 1 !important;
  color: rgb(255 0 0 / var(--tw-text-opacity)) !important
}

.\!tw-text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important
}

.tw-text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity))
}

.tw-text-blue-650 {
  --tw-text-opacity: 1;
  color: rgb(63 103 243 / var(--tw-text-opacity))
}

.tw-text-green-default {
  --tw-text-opacity: 1;
  color: rgb(0 128 0 / var(--tw-text-opacity))
}

.tw-text-inherit {
  color: inherit
}

.tw-text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity))
}

.tw-text-red-default {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity))
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.tw-text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(202 138 4 / var(--tw-text-opacity))
}

.tw-no-underline {
  -webkit-text-decoration-line: none;
          text-decoration-line: none
}

.tw-opacity-50 {
  opacity: 0.5
}

.tw-opacity-70 {
  opacity: 0.7
}

.tw-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.\!tw-transition-none {
  transition-property: none !important
}

.before\:tw-absolute::before {
  content: var(--tw-content);
  position: absolute
}

.before\:tw-bottom-8::before {
  content: var(--tw-content);
  bottom: 2rem
}

.before\:tw-z-\[-1\]::before {
  content: var(--tw-content);
  z-index: -1
}

.before\:tw-aspect-square::before {
  content: var(--tw-content);
  aspect-ratio: 1 / 1
}

.before\:tw-w-64::before {
  content: var(--tw-content);
  width: 16rem
}

.before\:tw--rotate-12::before {
  content: var(--tw-content);
  --tw-rotate: -12deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.before\:tw-rounded-\[30px\]::before {
  content: var(--tw-content);
  border-radius: 30px
}

.before\:tw-bg-blue-650::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(63 103 243 / var(--tw-bg-opacity))
}

.before\:tw-content-\[\'\'\]::before {
  --tw-content: '';
  content: var(--tw-content)
}

.first\:\!tw-ml-0:first-child {
  margin-left: 0px !important
}

@media (min-width: 768px) {
  .md\:tw-p-10 {
    padding: 2.5rem
  }

  .md\:tw-pb-6 {
    padding-bottom: 1.5rem
  }

  .md\:tw-pt-8 {
    padding-top: 2rem
  }
}

@media (max-width: 480.98px) {
  .max-xs\:tw-flex-col {
    flex-direction: column
  }

  .max-xs\:tw-text-center {
    text-align: center
  }
}

@media (min-width: 992px) {
  .md-lg\:tw-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .md-lg\:tw-items-start {
    align-items: flex-start
  }

  .md-lg\:tw-items-center {
    align-items: center
  }

  .md-lg\:tw-gap-x-6 {
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem
  }

  .md-lg\:tw-gap-x-8 {
    -webkit-column-gap: 2rem;
            column-gap: 2rem
  }

  .md-lg\:tw-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .md-lg\:tw-pb-16 {
    padding-bottom: 4rem
  }

  .md-lg\:\!tw-text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important
  }

  .md-lg\:\!tw-text-4xl {
    font-size: 2.25rem !important;
    line-height: 2.5rem !important
  }
}

@media (max-width: 991.98px) {
  .max-md-lg\:tw-mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .max-md-lg\:tw-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .max-md-lg\:tw-w-full {
    width: 100%
  }

  .max-md-lg\:tw-flex-col {
    flex-direction: column
  }

  .max-md-lg\:tw-items-start {
    align-items: flex-start
  }

  .max-md-lg\:tw-items-center {
    align-items: center
  }

  .max-md-lg\:tw-justify-start {
    justify-content: flex-start
  }

  .max-md-lg\:tw-gap-y-4 {
    row-gap: 1rem
  }

  .max-md-lg\:tw-gap-y-6 {
    row-gap: 1.5rem
  }

  .max-md-lg\:tw-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .max-md-lg\:tw-pb-8 {
    padding-bottom: 2rem
  }

  .max-md-lg\:tw-text-center {
    text-align: center
  }

  .max-md-lg\:\!tw-text-2xl {
    font-size: 1.5rem !important;
    line-height: 2rem !important
  }

  .max-md-lg\:\!tw-text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important
  }
}
